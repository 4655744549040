<div class="ion-text-center h-full">
  <ion-grid class="w-11/12 h-full">
    <div class="flex flex-col items-center justify-around h-full">
      <ion-row class="ion-padding">
        <div class="flex flex-col items-center justify-center">
          <ion-img class="" src="../../../assets/generic-logo.svg"></ion-img>
          <span class="font-bold">Nombre Empresa</span>
        </div>
      </ion-row>
      <ion-row class="ion-padding">
        <ion-text>
          <span class="home-header__span font-bold text-3xl">¡Bienvenido!</span>
        </ion-text>
      </ion-row>
      <ion-row class="ion-padding">
        <ion-text class="ion-padding">
          <span class="home-header__span font-bold text-lg"
            >Con solo tu numero de documento podrás descubrir las opciones de
            nuestros prestamos que mejor se adaptan a tus necesidades.
          </span>
        </ion-text>

        <ion-text class="ion-padding">
          <span class="home-header__span font-bold text-lg"
            >¿Te gustaría saber qué préstamos podemos ofrecerte?</span
          >
        </ion-text>
      </ion-row>
      <ion-row
        class="ion-justify-content-center ion-align-items-center ion-padding"
      >
        <section class="flex flex-col items-center">
          <ion-button
            (click)="navigateToNextStep()"
            expand="block"
            class="w-80 h-10"
            shape="round"
          >
            <span class="text-lg">¡Me interesa!</span>
          </ion-button>
        </section>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <section class="flex flex-row justify-between items-center gap-1">
          <ion-text>
            <span>Powered by</span>
          </ion-text>
          <ion-img
            class="footer-logo w-9 h-9"
            src="../../../assets/obd-logo.svg"
          >
          </ion-img>
          <ion-text class="font-bold leading-5 text-base">
            <span>Onboarding Digital</span>
          </ion-text>
        </section>
      </ion-row>
    </div>
  </ion-grid>
</div>
