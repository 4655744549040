<div
  class="message-container flex flex-row items-start w-full text-left"
  [ngClass]="
    messageSendedByUser ? 'flex-row-reverse text-right' : 'flex-row text-left'
  "
>
  <ion-avatar class="p-0 pt-2 m-0 flex flex-row items-start justify-center">
    <img
      alt="Silhouette of a person's head"
      [src]="messageSendedByUser ? ionicAvatar : obdAvatar"
    />
  </ion-avatar>
  <ion-card
    class="w-full p-0 m-0"
    [ngClass]="messageSendedByUser ? 'user-message-bg' : 'system-message-bg'"
  >
    <ion-card-content>
      <ion-text>
        <p [innerHTML]="message"></p>
      </ion-text>
    </ion-card-content>
  </ion-card>
</div>
