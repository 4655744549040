import { CommonModule, Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { IonButton, IonIcon } from '@ionic/angular/standalone';
import { debounceTime, interval, Subscription } from 'rxjs';
import { FaceApiService } from '../services/face-api.service';

@Component({
  selector: 'app-scan',
  standalone: true,
  imports: [IonIcon, IonButton, CommonModule],
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
})
export class ScanComponent implements OnInit {
  private video!: ElementRef;
  @ViewChild('video1') set content(content: ElementRef) {
    if (content) {
      this.video = content;
    }
  }

  currentUrl: string = '';
  isButtonDisabled = true;

  stream: MediaStream | null = null;
  subscriptions: Subscription = new Subscription();
  metadataSubscription: Subscription = new Subscription();
  errorImage: string = 'assets/images/oops-image.svg';
  currentGestureId: number = 0;

  showFrames: boolean = true;

  photoCaptured: boolean = false;

  constructor(
    private _router: Router,
    private _loadingCtrl: LoadingController,
    private _alertController: AlertController,
    private _toastController: ToastController,
    private location: Location,
    private _faceApiService: FaceApiService
  ) {}

  ngOnInit(): void {
    this._clearAllVideoTracks();

    this.subscriptions.add(
      this._faceApiService.metadata$
        .pipe(debounceTime(1000))
        .subscribe((metadata) => {
          if (metadata) {
            switch (this.currentGestureId) {
              case 0:
                this.isButtonDisabled = metadata.expressions.neutral < 0.5;
                if (metadata.expressions.neutral > 0.9) {
                  this.showLoading();
                }
                break;
              case 103:
                this.isButtonDisabled = metadata.expressions.surprised < 0.4;
                if (metadata.expressions.surprised > 0.9) {
                  this.showLoading();
                }
                break;
              case 106:
                setTimeout(() => {
                  this.isButtonDisabled = false;
                }, 2000);
                break;
              case 108:
                this.isButtonDisabled = metadata.expressions.happy < 0.4;
                if (metadata.expressions.happy > 0.9) {
                  this.showLoading();
                }
                break;
            }
          }
        })
    );
  }

  private _clearAllVideoTracks(): void {
    this.stream?.getTracks().forEach((t) => {
      t.stop();
      this.stream?.removeTrack(t);
    });
  }

  async ionViewDidEnter() {
    const loading = await this._loadingCtrl.create({
      message: 'Espere por favor...',
    });
    loading.present();
    if (this._faceApiService.globalFaceApi.nets.ssdMobilenetv1.params) {
      this._startVideo(loading);
    } else {
      this.subscriptions = this._faceApiService.modelsLoaded$.subscribe(
        (loaded) => {
          if (loaded) {
            this._startVideo(loading);
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.metadataSubscription.unsubscribe();
    this._clearAllVideoTracks();
  }

  async showLoading() {
    if (this.photoCaptured) return;
    this.photoCaptured = true;
    const loading: HTMLIonLoadingElement = await this._loadingCtrl.create({
      message: 'Estamos analizando la imagen...',
    });
    loading.present();
    this.takePhoto(loading);
  }

  takePhoto(loading: HTMLIonLoadingElement): void {
    this.showFrames = false;
    const video = this.video.nativeElement;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context?.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL('image/jpeg');
    this._saveImage(imageData, loading);
  }

  private _saveImage(image: string, loading: HTMLIonLoadingElement): void {
    setTimeout(() => {
      loading.dismiss();
      this.subscriptions.unsubscribe();
      this._navigateToNextStep();
    }, 3000);
  }

  private async _startVideo(loading?: HTMLIonLoadingElement) {
    try {
      const videoInput = this.video.nativeElement;
      const constraints = { video: { facingMode: 'user' } };

      // Verifica permisos y disponibilidad de la cámara
      if ('srcObject' in videoInput) {
        videoInput.srcObject = null;
      } else {
        videoInput.src = '';
      }

      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      if ('srcObject' in videoInput) {
        videoInput.srcObject = this.stream;
      } else {
        videoInput.src = window.URL.createObjectURL(this.stream as any);
      }

      await videoInput.play().then(() => {
        this._faceApiService.getMetadataFromFace(this.video);
        if (loading) {
          this.showFrames = true;
          loading.dismiss();
        }
      });

      this._showTopToast('Por favor, pon tu cara dentro del ovalo', 'top');
      this.metadataSubscription = interval(2000).subscribe(() => {
        this._faceApiService.getMetadataFromFace(this.video);
      });
    } catch (error: any) {
      if (loading) {
        loading.dismiss();
      }
      console.error(error);
      this._showCameraPermissionError();
    }
  }

  private _navigateToNextStep(): void {
    this._router.navigate(['exit']);
    this.ngOnDestroy();
  }

  private async _presentAlert() {
    const alert = await this._alertController.create({
      message: `
      <div class="card-alert">
        <img src="${this.errorImage}" class="card-alert-image">
        <br>
        <p>Necesitamos repetir la captura de tu rostro </p>
      </div>`,
      buttons: [
        {
          text: 'Volver a capturar',
          role: 'confirm',
          handler: () => {
            // this._clearSelfieImages();
            this.location.back();
          },
        },
      ],
    });

    await alert.present();
  }

  private async _showTopToast(
    text: string,
    position: 'top' | 'middle' | 'bottom'
  ) {
    const toast = await this._toastController.create({
      message: text,
      duration: 2000,
      position: position,
    });

    await toast.present();
  }

  private async _showCameraPermissionError(): Promise<void> {
    const toast = await this._toastController.create({
      message:
        'Ha ocurrido un error con los permisos de la camara. Vuelve a intentarlo',
      duration: 1500,
      position: 'top',
      color: '#cb1a27',
    });

    await toast.present();
  }
}
