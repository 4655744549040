import { Component, OnInit } from '@angular/core';
import {
  IonContent,
  IonText,
  IonImg,
  IonSpinner,
  IonToolbar,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import {
  AnimationLoader,
  LottieComponent,
  provideLottieOptions,
} from 'ngx-lottie';
import { ObdService } from '../services/obd.service';

@Component({
  selector: 'app-exit-screen',
  standalone: true,
  imports: [
    IonToolbar,
    IonSpinner,
    IonImg,
    IonText,
    IonContent,
    LottieComponent,
  ],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
  templateUrl: './exit-screen.component.html',
  styleUrls: ['./exit-screen.component.scss'],
})
export class ExitScreenComponent implements OnInit {
  animation = {
    path: '../../../assets/animations/success.json',
    loop: false,
  };

  private _host: string = 'pwa.obd.com.ar';
  private _url: string = `https://${this._host}/start/08a6d613-c506-43de-b74c-16905a011c4e`;

  constructor(private _obdService: ObdService) {}

  ngOnInit() {
    this._obdService.getObdLink().subscribe((resp) => {
      if (resp) {
        const URL = `https://${this._host}/start/${resp.transaccion}`;
        setTimeout(() => {
          this.goToLink(URL);
        }, 5000);
      }
    });
  }

  goToLink(url: string) {
    window.open(url);
  }
}
