<ion-toolbar class="w-full" color="ligth">
  <div class="flex flex-row items-center ml-4 my-2">
    <ion-img class="w-10 h-10 mr-2" src="../../../assets/generic-logo.svg">
    </ion-img>
    <ion-text class="my-1">
      <p class="font-bold">Nombre Empresa</p>
    </ion-text>
  </div>
</ion-toolbar>
<div class="h-full flex flex-col justify-evenly items-center">
  <ng-lottie class="lottie-animation" [options]="animation"> </ng-lottie>

  <ion-text
    color="dark"
    class="flex flex-col justify-center items-center lottie-text ion-padding"
  >
    <h1 class="font-semibold">¡Excelente!</h1>
    <h3 class="font-bold">
      Estamos redirigiendote al servicio de onboarding para avanzar con tu
      solicitud
    </h3>
    <ion-spinner name="dots" class="mt-5"></ion-spinner>
  </ion-text>

  <section class="flex flex-row justify-between items-center gap-1">
    <ion-text class="mr-1">
      <span>Powered by</span>
    </ion-text>
    <ion-img class="footer-logo w-9 h-9" src="../../../assets/obd-logo.svg">
    </ion-img>
    <ion-text class="font-bold leading-5 text-base">
      <span>Onboarding Digital</span>
    </ion-text>
  </section>
</div>
