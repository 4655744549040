<ion-toolbar class="w-full" color="ligth">
  <div class="flex flex-row items-center ml-4 my-2">
    <ion-img class="w-10 h-10 mr-2" src="../../../assets/generic-logo.svg">
    </ion-img>
    <ion-text class="my-1">
      <p class="font-bold">Nombre Empresa</p>
    </ion-text>
  </div>
</ion-toolbar>
<div class="ion-text-center h-full">
  <ion-grid class="w-11/12 h-full">
    <form
      action=""
      [formGroup]="cuotasForm"
      (ngSubmit)="onSubmit()"
      class="h-full"
    >
      <div class="flex flex-col items-center justify-around h-full">
        <ion-row class="ion-padding">
          <p class="mx-5">
            <ion-text>
              Estás en condiciones de acceder a un crédito por un monto total
              de:
            </ion-text>
          </p>
          <ion-card class="w-full">
            <ion-card-content>
              <ion-text class="text-xl">
                {{ "$ 19.400.000,00 ARS" }}
              </ion-text>
            </ion-card-content>
          </ion-card>

          <p class="w-full pt-5">
            <ion-text class="font-bold">
              Selecciona el número de cuotas:
            </ion-text>
          </p>
          <ion-list class="w-full ion-padding">
            <ion-item>
              <ion-select
                placeholder="Cuotas"
                label-placement="stacked"
                formControlName="cuotasId"
              >
                <ion-select-option
                  *ngFor="let cuota of cuotas"
                  [value]="cuota.id"
                  >{{ cuota.description }}</ion-select-option
                >
              </ion-select>
            </ion-item>
          </ion-list>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <p class="ion-padding w-3/4">
            <ion-text class="font-bold text-lg">
              ¿Querés continuar hacia tu préstamo?
            </ion-text>
          </p>
          <ion-checkbox labelPlacement="end" formControlName="terms"
            >Acepto los términos y condiciones</ion-checkbox
          >
          <section class="flex flex-col items-center ion-padding">
            <ion-button
              (click)="navigateToNextStep()"
              expand="block"
              class="w-80 h-10"
              shape="round"
              type="submit"
              [disabled]="cuotasForm.invalid"
            >
              <span class="text-lg">Continuar </span>
            </ion-button>
          </section>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <section class="flex flex-row justify-between items-center gap-1">
            <ion-text>
              <span>Powered by</span>
            </ion-text>
            <ion-img
              class="footer-logo w-9 h-9"
              src="../../../assets/obd-logo.svg"
            >
            </ion-img>
            <ion-text class="font-bold leading-5 text-base">
              <span>Onboarding Digital</span>
            </ion-text>
          </section>
        </ion-row>
      </div>
    </form>
  </ion-grid>
</div>
