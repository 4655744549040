<ion-toolbar class="w-full" color="ligth">
  <div class="flex flex-row items-center ml-4 my-2">
    <ion-img class="w-10 h-10 mr-2" src="../../../assets/generic-logo.svg">
    </ion-img>
    <ion-text class="my-1">
      <p class="font-bold">Nombre Empresa</p>
    </ion-text>
  </div>
</ion-toolbar>
<div class="ion-text-center h-full">
  <ion-grid class="w-11/12 h-full">
    <div class="flex flex-col items-center justify-around h-full">
      <ion-row class="">
        <p class="mx-5 font-bold">
          <ion-text>
            Vamos a firmar nuestro contrato de términos y condiciones. Para
            esto, necesitamos tomarte una selfie...
          </ion-text>
        </p>
        <p class="mx-2 pt-5">
          <ion-text>
            Asegurate de que haya buena iluminación y tomate una foto de tu
            rostro manteniendo un gesto neutral
          </ion-text>
        </p>
      </ion-row>
      <ion-row class="justify-center">
        <ion-img class="w-2/3 ion-padding" src="../../../assets/neutral.png">
        </ion-img>
        <section class="flex flex-col items-center">
          <ion-button
            (click)="navigateToNextStep()"
            expand="block"
            class="w-80 h-10"
            shape="round"
          >
            <span class="text-lg">Tomar foto</span>
          </ion-button>
        </section>
      </ion-row>

      <ion-row class="ion-justify-content-center ion-align-items-center">
        <section class="flex flex-row justify-between items-center gap-1">
          <ion-text>
            <span>Powered by</span>
          </ion-text>
          <ion-img
            class="footer-logo w-9 h-9"
            src="../../../assets/obd-logo.svg"
          >
          </ion-img>
          <ion-text class="font-bold leading-5 text-base">
            <span>Onboarding Digital</span>
          </ion-text>
        </section>
      </ion-row>
    </div>
  </ion-grid>
</div>
