import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChatComponent } from './chat/chat.component';
import { ProductOverviewComponent } from './product-overview/product-overview.component';
import { ExitScreenComponent } from './exit-screen/exit-screen.component';
import { ScanTutorialComponent } from './scan-tutorial/scan-tutorial.component';
import { ScanComponent } from './scan/scan.component';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'overview', component: ProductOverviewComponent },
  { path: 'pre-scan', component: ScanTutorialComponent },
  { path: 'scan', component: ScanComponent },
  { path: 'exit', component: ExitScreenComponent },
];
