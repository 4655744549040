import { Component, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { addIcons } from 'ionicons';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { FaceApiService } from './services/face-api.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [IonRouterOutlet, IonApp, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private _faceApiService: FaceApiService) {}
  title = 'ng-obd-qr';
}
