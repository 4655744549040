import type { HttpInterceptorFn } from '@angular/common/http';

export const httpRequestInterceptor: HttpInterceptorFn = (req, next) => {
  req = req.clone({
    headers: req.headers.set(
      'Authorization',
      `Token 08ac75122a208298b15ad3ff8e0ee445c2996a2c`
    ),
  });
  return next(req);
};
