import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  IonContent,
  IonImg,
  IonText,
  IonRow,
  IonGrid,
  IonButton,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [IonButton, IonGrid, IonRow, IonText, IonImg, IonContent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  constructor(private _router: Router) {}
  navigateToNextStep(): void {
    this._router.navigate(['chat']);
  }
}
