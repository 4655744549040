import { Component, Input, OnInit } from '@angular/core';
import { addIcons } from 'ionicons';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonAvatar,
  IonText,
} from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [
    IonText,
    IonAvatar,
    IonCardSubtitle,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    CommonModule,
  ],
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() messageSendedByUser: boolean = false;
  @Input() message: string = '';

  ionicAvatar: string = '../../../assets/avatar.svg';
  obdAvatar: string = '../../../assets/generic-logo.svg';

  constructor() {}

  ngOnInit() {}
}
