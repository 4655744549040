import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  IonToolbar,
  IonImg,
  IonText,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonList,
  IonCheckbox,
  IonButton,
  IonItem,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-scan-tutorial',
  standalone: true,
  imports: [
    IonItem,
    IonButton,
    IonCheckbox,
    IonList,
    IonCardContent,
    IonCard,
    IonRow,
    IonGrid,
    IonText,
    IonImg,
    IonToolbar,
  ],
  templateUrl: './scan-tutorial.component.html',
  styleUrls: ['./scan-tutorial.component.scss'],
})
export class ScanTutorialComponent implements OnInit {
  constructor(private _router: Router) {}

  ngOnInit() {}

  navigateToNextStep(): void {
    this._router.navigate(['scan']);
  }
}
