@if (showFrames) {
<div class="video-container">
  <video
    #video1
    capture="environment"
    class="video"
    playsinline
    autoplay
    muted
  ></video>
  <div class="center-box"></div>
  <!-- <div class="button-container flex flex-col justify-center items-center">
    <ion-button
      (click)="showLoading()"
      shape="round"
      size="large"
      [disabled]="isButtonDisabled"
    >
      <ion-icon slot="icon-only" name="camera-outline" size="large"></ion-icon>
    </ion-button>
  </div> -->
</div>
}@else{
<ng-template #loading></ng-template>

}
