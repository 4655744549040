import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { addIcons } from 'ionicons';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonText,
  IonButton,
  IonImg,
  IonInput,
  IonItemDivider,
  IonIcon,
  IonToolbar,
  IonHeader,
} from '@ionic/angular/standalone';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { logoIonic, send } from 'ionicons/icons';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { Router, RouterModule } from '@angular/router';
import { ObdService } from '../services/obd.service';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    IonHeader,
    IonToolbar,
    IonIcon,
    IonItemDivider,
    IonInput,
    IonImg,
    IonButton,
    IonText,
    IonRow,
    IonGrid,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    ChatMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  newMessageForm: FormGroup = this._fb.group({});
  messages: { messageSendedByUser: boolean; messageBody: string }[] = [];

  //Este contador sirve para identificar cuantos mensajes CORRECTOS envio el usuario.
  userMessagesCounter: number = 0;
  systemMessagesCounter: number = 0;

  //Mensajes estaticos
  systemMessage1 = `¡Hola! ¿Te gustaría solicitar un préstamo?\n1 - Sí, me interesa!\n2 - No, gracias`;
  systemMessage1Error = `¡Ups, tuvimos un problema!\nPor favor ingresa unicamente una de las opciones indicadas: \n1 - Sí, me interesa!\n2 - No, gracias`;
  systemMessage1Exit = `Gracias, puede volver a ingresar a nuestro sitio en otro momento.`;

  systemMessage2 = `Por favor ingresá tu número de DNI. Ingresalo sin puntos, espacios ni guiones,  seguí este formato:\n22333444`;
  systemMessage2Error = `¡Ups, tuvimos un problema!\nPor favor ingresá tu número de DNI. Ingresalo sin puntos, espacios ni guiones,  seguí este formato:\n22333444`;

  systemMessage3 = `Excelente, danos un momento para comprobar el mejor préstamo que podemos ofrecerte...`;
  systemMessage3Error = `¡Ups, tuvimos un problema! Por favor intentalo más tarde`;

  systemMessage4 = `<a [routerLink]=['overview']>Ver la oferta</a>`;

  @ViewChild('messageContainer', { static: false })
  messageContainer!: ElementRef;

  constructor(
    private _fb: FormBuilder,
    private _loadingCtrl: LoadingController,
    private _router: Router,
    private elementRef: ElementRef,
    private _obdService: ObdService
  ) {
    addIcons({ send });
  }

  ngOnInit() {
    this._formBuilder();
    this._insertSystemMessage(this.systemMessage1);
  }

  async submitMessage(): Promise<void> {
    if (this.newMessageForm.invalid) {
      this.newMessageForm.markAllAsTouched();
      return;
    }
    const message: string = this.newMessageForm.get('message')?.value;
    if (message === '') return;
    if (this._validateUserMessage(message)) {
      this._insertUserMessage(message);
      //Si es el primer mensaje y el usuario indica que no esta interesado:
      if (this.userMessagesCounter === 0 && message == '2') {
        this._userNotInterested();
        return;
      }

      //Indico que se inserto un nuevo mensaje por parte del usuario
      this.userMessagesCounter++;

      //Pido el proximo mensaje del sistema
      const sucessMessage = this._getSystemSucessMessage();
      this._insertSystemMessage(sucessMessage);

      if (this.systemMessagesCounter >= 3) {
        this._obdService.clientDni$.next(message);
        setTimeout(() => {
          this._router.navigate(['overview']);
        }, 4000);
      }
    } else {
      this._insertUserMessage(message);
      const errorMessage = this._getSystemErrorMessage();
      this._insertSystemMessage(errorMessage);
      this.systemMessagesCounter--;
    }

    setTimeout(() => {
      if (this.messageContainer && this.messageContainer.nativeElement) {
        this.messageContainer.nativeElement.scrollTop =
          this.messageContainer.nativeElement.scrollHeight;
      }
    }, 10);
  }

  private _userNotInterested(): void {
    this._insertSystemMessage(this.systemMessage1Exit);
    this.newMessageForm.get('message')?.setValue('');
    this.newMessageForm.disable();
  }

  private _validateUserMessage(currentMessageToEvaluate: string): boolean {
    if (currentMessageToEvaluate == '') return false;
    switch (this.userMessagesCounter) {
      case 0:
        if (
          currentMessageToEvaluate == '1' ||
          currentMessageToEvaluate == '2'
        ) {
          return true;
        }
        return false;

      case 1:
        if (
          currentMessageToEvaluate.length >= 7 &&
          currentMessageToEvaluate.length <= 9
        ) {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  private _insertSystemMessage(message: string): void {
    this.systemMessagesCounter++;
    const formattedMessage = message.replace(/\n/g, '<br>');
    this.messages.push({
      messageSendedByUser: false,
      messageBody: formattedMessage,
    });
    setTimeout(() => {
      if (this.messageContainer && this.messageContainer.nativeElement) {
        this.messageContainer.nativeElement.scrollTop =
          this.messageContainer.nativeElement.scrollHeight;
      }
    }, 300);
  }

  private _getSystemErrorMessage(): string {
    switch (this.userMessagesCounter) {
      case 0:
        return this.systemMessage1Error;

      case 1:
        return this.systemMessage2Error;

      default:
        return this.systemMessage3Error;
    }
  }

  private _getSystemSucessMessage(): string {
    if (this.userMessagesCounter > 2) {
    }
    switch (this.userMessagesCounter) {
      case 0:
        return this.systemMessage1;
      case 1:
        return this.systemMessage2;
      case 2:
        return this.systemMessage3;
      default:
        this.showLoading();
        return this.systemMessage4;
    }
  }

  private _insertUserMessage(message: string): void {
    this.messages.push({
      messageSendedByUser: true,
      messageBody: message,
    });
    this.newMessageForm.get('message')?.setValue('');
  }

  async showLoading() {
    const loading: HTMLIonLoadingElement = await this._loadingCtrl.create({
      message: 'Estamos verificando tu linea de crédito...',
      duration: 4000,
    });
    loading.present();

    await setTimeout(() => {
      this._redirectToNextStep();
    }, 4000);
  }

  private _redirectToNextStep(): void {
    this._router.navigate(['overview']);
  }

  private _formBuilder(): void {
    this.newMessageForm = this._fb.group({
      message: ['', [Validators.minLength(1), Validators.required]],
    });
  }
}
